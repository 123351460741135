import type { DepartmentOptionsObject } from "../../translation-keys/department-option-keys.js";

const FormDepartmentOptions: DepartmentOptionsObject = {
  "Accounting/Finance": "会計/財務",
  "Blood Bank": "血液バンク",
  Clinical: "臨床",
  "Customer Service": "カスタマーサービス",
  "Document Control": "文書管理",
  EHS: "EHS",
  Engineering: "エンジニアリング",
  "Executive (C-level or VP)": "エグゼクティブ（CレベルまたはVP）",
  "Human Resources": "人的資源",
  "IT / Information Systems": "IT/情報システム",
  Laboratory: "研究所",
  Manufacturing: "製造",
  Operations: "オペレーション",
  Other: "その他",
  Packaging: "包装",
  "Product Development": "製品開発",
  Purchasing: "購買",
  Quality: "品質",
  "R&D": "研究開発",
  "R&D QA": "研究開発QA",
  Regulatory: "薬事",
  "Sales / Marketing": "営業/マーケティング",
  Training: "トレーニング",
  Validation: "バリデーション",
};

export default FormDepartmentOptions;
